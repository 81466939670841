<template>
  <div>
    <validation-observer ref="ChangeAssetOwnerForm">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="addChangeAssetOwner"
      >
        <div class="col-12">
          <div v-if="!isCheckAllFilterResults">
            <span style="color: #5e5873; font-size: 0.857rem"
              >Asset Name :</span
            >
            <div class="w-100">
              <b-badge
                v-for="asset in asset_id"
                :key="asset"
                variant="light-primary"
                class="mb-1 mr-1"
              >
                {{ asset.asset_name }}
              </b-badge>
            </div>
          </div>
          <div v-else class="w-100">
            <div v-for="f in filter" :key="f" class="d-flex">
              <b-badge variant="light-primary" v-if="f.group" class="mb-1">
                {{ f.group.text }}
              </b-badge>
              <span v-if="f.risk_level" class="mb-1">
                <!-- {{ f.risk_level.text }} -->
                <span v-for="r in f.risk_level" :key="r">
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 0"
                    >Unknown</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 1"
                    >Safe</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 2"
                    >Low</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 3"
                    >Medium</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 4"
                    >High</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 5"
                    >Critical</b-badge
                  >
                </span>
              </span>
              <b-badge
                variant="light-primary"
                v-if="f.asset_grouptype"
                class="mb-1"
              >
                {{ f.asset_grouptype }}
              </b-badge>
            </div>
          </div>
          <div>
            <b-form-group
              v-if="is_staff"
              label="Select Organization"
              label-for="Organization"
            >
              <v-select
                v-model="organization"
                label="name"
                placeholder="--Select Organization--"
                :options="organizations"
                @input="loadOrgUsers"
                autocomplete
                style="width: 100%"
                :reduce="(organization) => organization.tenant_uid"
                disabled
              />
            </b-form-group>
          </div>
          <b-form-group label="Select User" label-for="user">
            <template #label>
              <span>Select User</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <v-select
              v-model="user"
              label="text"
              placeholder="--Search User--"
              :options="users"
              autocomplete
              :reduce="(user) => user.value"
            />
          </b-form-group>
        </div>
        <div class="row">
          <div class="col-12">
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              :disabled="user === '' || disabled"
              class="ml-1"
            >
              <span v-if="isLoading">
                <b-spinner type="border" small></b-spinner>
                Please wait
              </span>
              <span v-else>Add</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelect,
    BBadge,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      // selectedOrganization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // asset_id: [],
      asset_options: [],
      user: "",
      users: [],
      //   organization: "",
      // organizations: this.$store.state.app.organizations,
      organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      isLoading: false,
      disabled: false,
    };
  },
  props: {
    asset_id: {
      type: Array,
      required: true,
    },
    isCheckAllFilterResults: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    parentCallBack: Function,
  },
  computed: {
    // organization() {
    //   return this.organizations.find(
    //     (org) => org.value === this.$store.state.app.org_id
    //   );
    // },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.organization) {
        return this.organization.value;
      }
      return null;
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else {
        return this.$store.state.app.organizationId;
        // return this.organization.id;
      }
      return null;
    },
  },
  created: function () {
    this.loadOrgUsers();
    // this.loadOrg();
    // this.fetchAssetOptions();
    //  this.load(); --> not calling this due page load issues
  },
  methods: {
    load: function () {},
    loadOrgUsers: function () {
      this.loading = true;
      // Load User drop down
      let url =
        process.env.VUE_APP_BASEURL + "user/users/get-all-users/?query=true";
      if (this.is_staff) {
        url = url + "&q_org_id=" + this.selectedOrganizationRefid;
      }

      if (this.organization != null) {
        if (this.organization == "system") {
          url = url + "&role_type=" + 0;
        } else {
          url = url + "&q_org_id=" + this.selectedOrganizationRefid;
        }
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;

      this.$http(o_options).then((res) => {
        this.users = [{ value: null, text: "--Search User--" }];
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].first_name + " " + res.data[key].last_name,
          };
          self.users.push(user);
        });
        this.loading = false;
      });
    },
    addChangeAssetOwner: function () {
      this.$refs.ChangeAssetOwnerForm.validate().then((success) => {
        this.isLoading = true;
        this.disabled = true;
        if (success) {
          let data = {
            // assets: this.asset_id,
            assets: this.asset_id.map((asset) => asset.asset_id),
            asset_owner: this.user,
            org_id: this.selectedOrganizationId,
          };
          if (this.isCheckAllFilterResults) {
            data.filters = this.filter.map((item) => {
              if (item.group && typeof item.group === "object") {
                return { group: item.group.value };
              }
              if (item.risk_level && typeof item.risk_level === "object") {
                return { risk_level: item.risk_level.value };
              }

              return item;
            });

            // data.filters = this.filter;
          }
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "asset/change-asset-owner/",
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res.data, "add user asset");
            if (res.data.status == "success") {
              this.$router.push({
                name: "Assets",
              });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isLoading = false;
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
              this.isLoading = false;
            }
            this.isLoading = false;
            this.disabled = false;
            this.parentCallBack();
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
